import React from "react";
import CustomerViews from "../components/CustomerViews/CustomerViews";
import Layout from "../components/Layout/Layout";
import HappyFaces from "../components/HappyFaces/HappyFaces";
import Seo from "../components/Seo";
import FreeDiscount from "../components/Discount/FreeDiscount";
import HeroSectionFree from "../components/HeroSection/HeroSectionFree";

const FreeView = () => {
  return (
    <>
      <Seo
        title="Get Free Instagram Views Trial with Stagroo"
        description="Getting free Instagram views is easy with Stagroo! We'll help you get more engagement with our free trial. Take your social media to the next level!"
        canonical="/free-instagram-views"
        keywords="free instaggram views"
      />

      <Layout>
        <HeroSectionFree
          heading={"Free Instagram Views Trial"}
          description={
            "The Views that Stagroo delivers are real and authentic, coming from legitimate IG users with real accounts on the app."
          }
        />
        <FreeDiscount from="views" />
        <HappyFaces />
        <CustomerViews />
      </Layout>
    </>
  );
};
export default FreeView;
